<template>
	<div>
		<v-text-field
			clearable
			label="Pet Name"
			outlined
			@blur="fetchPets"
		>
		</v-text-field>
		<AdminPetsEdit :pet="pet" />
	</div>
</template>

<script>
import AdminPetsEdit from '@/views/admin/components/AdminPetsEdit';

export default {
	name: 'AdminPetsPage',
	components: { AdminPetsEdit },
	data: () => ({
		pet: {}
	}),
	methods: {
		async fetchPets(ele) {
			let id = ele.target.value;
			if (!id) {
				this.pet = {};
				return;
			}
			id = id.trim().toLowerCase();
			let result = await this.$store.dispatch('getAdminPet', id);
			result.newId = result.id;
			this.pet = result;
		}
	},
	mounted() {
	},
};
</script>

<style scoped>

</style>
