<template>
	<div>
		<v-card :disabled="!pet.id">
			<v-form
				ref="form"
				class="form"
				lazy-validation
			>
				
				<div class="pet-title">
					<div class="avatar-div">
						<v-img contain class="avatar" :src="petUrl" alt="avatar" />
					</div>
					<div>
						<v-card-title class="pt-8">
							{{ pet.name }}
						</v-card-title>
						<v-card-subtitle>
							{{ pet.rank }}
						</v-card-subtitle>
					</div>
				</div>
				<v-card-text>
					<v-text-field
						class="pt-3"
						v-model="pet.newId"
						label="Pet ID"
						counter="50"
						required
						dense
						outlined
					></v-text-field>
					<v-text-field
						:rules="altRules"
						v-model="alt"
						label="Pet Aliases (comma separated)"
						dense
						outlined
					></v-text-field>
					<div class="stat-row">
						<v-img
							src="https://cdn.discordapp.com/emojis/531620120410456064.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.hp"
							label="HP"
							type="number"
							required
							dense
							outlined
						></v-text-field>
						<v-img
							src="https://cdn.discordapp.com/emojis/531616155450998794.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.att"
							label="ATT"
							type="number"
							required
							dense
							outlined
						></v-text-field>
						<v-img
							src="https://cdn.discordapp.com/emojis/531616156222488606.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.pr"
							label="PR"
							type="number"
							required
							dense
							outlined
						></v-text-field>
					</div>
					<div class="stat-row">
						<v-img
							src="https://cdn.discordapp.com/emojis/531620120976687114.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.wp"
							label="WP"
							type="number"
							required
							dense
							outlined
						></v-text-field>
						<v-img
							src="https://cdn.discordapp.com/emojis/531616156231139338.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.mag"
							label="MAG"
							type="number"
							required
							dense
							outlined
						></v-text-field>
						<v-img
							src="https://cdn.discordapp.com/emojis/531616156226945024.png"
							class="stat-icon"
							max-height="30"
							max-width="30"
						/>
						<v-text-field
							class="stat"
							:rules="statRules"
							v-model="pet.mr"
							label="MR"
							type="number"
							required
							dense
							outlined
						></v-text-field>
					</div>
					<div>= {{ statTotal }} total</div>
					<v-textarea
						class="pt-3"
						:rules="descRules"
						v-model="pet.description"
						label="Pet Description"
						counter="2000"
						dense
						outlined
					></v-textarea>
				</v-card-text>
				<v-card-actions class="actions">
					<v-spacer></v-spacer>
					<div v-if="errorMsg" class="error-text">{{ errorMsg }}</div>
					<v-btn color="primary" @click="submit" :loading="loading">
						Submit
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'AdminPetsEdit',
	props: {
		pet: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		loading: false,
		form: null,
		errorMsg: null,
		altRules: [
			(v) => {
				if (!v) return true;
				const names = v.split(',');
				if (names.length > 5) {
					return 'You can only have 5 aliases.';
				}
				for (let i in names) {
					const name = names[i];
					if (name.length > 32) {
						return 'Pet name must be under 32 characters';
					} else if (name.length < 3) {
						return 'Pet name must be at least 2 characters';
					}
				}
				return true;
			},
			(v) => !v || !!v.match(/^[a-zA-z0-9_,]+$/) || 'Pet name can only contain alphanumeric characters and underscores',
		],
		statRules: [
			(v) => !!v || 'required',
			(v) => parseInt(v) > 0 || 'Must be greater than 0',
		],
		descRules: [
			(v) => !v || v.length <= 2000 || 'Pet name must be under 2000 characters',
		],
	}),
	computed: {
		petUrl () {
			return this.getEmojiUrl(this.pet.id)
		},
		alt: {
			get() {
				const alt = this.pet?.alt || [];
				return alt.join(',');
			},
			set(value) {
				value = value.split(',');
				this.pet.alt = value;
			},
		},
		statTotal() {
			return parseInt(this.pet.hp)
				+ parseInt(this.pet.att)
				+ parseInt(this.pet.pr)
				+ parseInt(this.pet.wp)
				+ parseInt(this.pet.mag)
				+ parseInt(this.pet.mr);
		}
	},
	methods: {
		async submit() {
			this.loading = true;
			this.errorMsg = null;
			const form = {
				description: this.pet.description,
				id: this.pet.id,
				alt: [...this.pet.alt],
				hp: parseInt(this.pet.hp),
				att: parseInt(this.pet.att),
				pr: parseInt(this.pet.pr),
				wp: parseInt(this.pet.wp),
				mag: parseInt(this.pet.mag),
				mr: parseInt(this.pet.mr),
				newId: this.pet.newId
			};

			try {
				await this.$store.dispatch('editAdminPet', form);
			} catch(err) {
				console.error(err);
				const opt = {
					text: 'Failed to submit the pet... try again later!',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
			} finally {
				this.loading = false;
			}
			const opt = {
				text: 'Successfully updated!',
				imgUrl: 'owo-peek.png',
			};
			this.$modal(opt).showInfo();
		}
	}
};
</script>

<style scoped>
.actions {
	padding: 16px;
	width: 100%;
}

.error-text {
	color: var(--v-error-base);
	padding-right: 10px;
}

.avatar {
	margin-right: 10px;
	width: 64px;
	height: 64px;
	margin-left: 20px;
}

.stat-row {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.stat-icon {
	margin-top: 4px;
	margin-right: -5px;
}

.pet-title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
</style>
